
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { Heading1 } from '@lumoslabs/lumosity-storybook'
import { NextPage } from 'next'
import Head from 'next/head'

import MobileDevicesInterstitial from '~/components/MobileDevicesInterstitial'
import { MigrationModal } from '~/components/modals/MigrationModal'
import QueryLoader from '~/components/QueryLoader'
import StreakCard from '~/components/streaks/StreakCard'
import StreakCounter, { StreakContainer } from '~/components/streaks/StreakCounter'
import TopNav from '~/components/TopNav'
import PageContent from '~/components/ui/PageContent'
import PageDate from '~/components/ui/PageDate'
import WorkoutCard from '~/components/ui/WorkoutCard'
import { AnalyticsCategory, ScreenName } from '~/context/AnalyticsContext'
import { useGetTodaysWorkoutQuery, useGetUserQuery } from '~/graphql/generated/schema'
import useTrackScreenView from '~/hooks/analytics/useTrackScreenView'
import { useAvailableGames } from '~/hooks/useAvailableGames'
import useIsMobileDevice from '~/hooks/useIsMobileDevice'
import useLocalDate from '~/hooks/useLocalDate'
import useMarketingPageView from '~/hooks/useMarketingPageView'
import useScrollToTop from '~/hooks/useScrollToTop'
import useSetL2UserCookie from '~/hooks/useSetL2UserCookie'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { withApollo } from '~/lib/withApollo'
import { getDate } from '~/utils/getDate'

interface HomeProps {
  date: Date
}

const Home: NextPage<HomeProps> = () => {
  // not actually the page slug since this is the root page, but calling it home since that's the tracking name!
  const pageSlug = 'home'
  const t = useTranslationForNamespace(pageSlug)
  useTrackScreenView(ScreenName.Home, AnalyticsCategory.Navigation)
  useMarketingPageView(pageSlug)

  useSetL2UserCookie()

  const { data: userQueryData } = useGetUserQuery({ fetchPolicy: 'cache-and-network' })
  const name = userQueryData?.me?.firstName
  const { dateFormatted, greetingKey } = useLocalDate()
  const isMobileDevice = useIsMobileDevice()
  useScrollToTop()

  const availableGameSlugs = useAvailableGames()
  const { data, loading, error } = useGetTodaysWorkoutQuery({
    variables: { knownGameSlugs: availableGameSlugs, currentDate: getDate() },
    fetchPolicy: 'cache-and-network',
    // see https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    nextFetchPolicy: 'cache-first',
  })

  if (isMobileDevice) {
    return <MobileDevicesInterstitial />
  }

  const greeting = name ? t(`greetingWithName.${greetingKey()}`, { name: name }) : t(`greeting.${greetingKey()}`)

  return (
    <>
      <Head>
        <title>{t('title')}</title>
      </Head>
      <TopNav />
      <MigrationModal />
      <PageContent>
        <PageContent.Header>
          <StreakContainer>
            <StreakCounter />
          </StreakContainer>
          <PageDate dateString={dateFormatted} />
          <Heading1 as='div'>{greeting}</Heading1>
        </PageContent.Header>
        <PageContent.Main>
          <QueryLoader data={data} loading={loading} error={error}>
            {data && <WorkoutCard workout={data} />}
          </QueryLoader>
        </PageContent.Main>
        <PageContent.Sidebar>
          <StreakCard />
        </PageContent.Sidebar>
      </PageContent>
    </>
  )
}

const __Page_Next_Translate__ = withApollo({ ssr: true })(Home)


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  